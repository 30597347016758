<template>
	<div class="section_wrap pt-50">
		<div class="subindex_wrap">
			<div class="subindex_head">
				<div class="container">
					<div class="subindex_box_1">
						<span class="cell_group_tit">{{  $language.common.limit }}</span>
						<ul class="settings_list">
							<li class="settings_list_item">
								<button
									@click="is_confirm = true"
								>
									<div class="cell_area">
										<div class="cell_tit">{{  $language.common.disclosure_active }}</div>
										<div class="cell_action"><span class="text">{{ is_active }}</span><i class="icon icon-arrow-right"></i></div>
									</div>
								</button>
							</li>
							<li class="settings_list_item">
								<button
									@click="$emit('to', { name: 'mafia112' })"
								>
									<div class="cell_area">
										<div class="cell_tit">{{  $language.auth.block_account }}</div>
										<div class="cell_action"><span class="text"> {{ item_user.blok_member_count }}</span><i class="icon icon-arrow-right"></i></div>
									</div>
								</button>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="subindex_item">
				<div class="container">
					<div class="subindex_box_1">
						<span class="cell_group_tit">{{  $language.auth.auth_number }}</span>
						<ul class="settings_list">
							<li class="settings_list_item">
								<button @click="onPin('check')">
									<div class="cell_area">
										<div class="cell_tit">{{  $language.auth.change_pin_number }}</div>
										<div class="cell_action"><i class="icon icon-arrow-right"></i></div>
									</div>
								</button>
							</li>
							<li class="settings_list_item">
								<button @click="$emit('to', {name: 'mafia1113'})">
									<div class="cell_area">
										<div class="cell_tit">Google OTP</div>
										<div class="cell_action"><i class="icon icon-arrow-right"></i></div>
									</div>
								</button>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="subindex_item">
				<div class="container">
					<div class="subindex_box_1">
						<span class="cell_group_tit">{{  $language.common.account }}</span>
						<ul class="settings_list">
							<li class="settings_list_item">
								<button @click="type = 'logout'; is_1112 = true">
									<div class="cell_area">
										<div class="cell_tit">{{  $language.common.logout }}</div>
										<div class="cell_action"><i class="icon icon-arrow-right"></i></div>
									</div>
								</button>
							</li>
							<li class="settings_list_item">
								<button @click="$emit('to', { name: 'mafia1111'})">
									<div class="cell_area">
										<div class="cell_tit">{{  $language.common.withdrawal }}</div>
										<div class="cell_action"><i class="icon icon-arrow-right"></i></div>
									</div>
								</button>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<mafia1112
			v-if="is_1112"
			:type="type"

			@cancel="is_1112 = !is_1112"
			@click="postLogout"
		></mafia1112>

		<Popup_confirm
			v-if="is_confirm"
			@click="postCartelActivity(true)"
			@cancel="postCartelActivity(false)"
		>
			<template v-slot:title>{{  $language.common.disclosure_active }}</template>
			<template
				v-slot:main-txt
			>{{  $language.disclosure_message.reveal_01 }}</template>
			<template
				v-slot:sub-txt
			>{{  $language.disclosure_message.reveal_02 }}</template>
			<template
				v-slot:name-cancel
			>{{  $language.common.Nondisclosure }}</template>
			<template
				v-slot:name-confirm
			>{{  $language.common.disclosure }}</template>
		</Popup_confirm>
	</div>
</template>

<script>

import mafia1112 from "@/view/Mypage/mafia111-2"
import Popup_confirm from "@/view/Layout/PopupConfirm";

export default {
	name: 'mafia111'
	, components: {Popup_confirm, mafia1112}
	, props: ['Axios', 'callBack', 'user']
	, data: function(){
		return {
			program: {
				name: this.$language.agreement.privacy_defence
				, title: this.$language.agreement.privacy_defence
				, not_footer: true
				, type: 'mypage'
				, from: 'myPage'
				, callBack: {
					name: 'pin_check'
				}
			}
			, pin_option: {
				pin_type: 'check'
			}
			, is_pin: false
			, is_1112: false
			, type: 'logout'
			, is_confirm: false
			, item_user: {}
			, is_otp: false
		}
	}
	, computed: {
		is_active: function(){
			let t = this.$language.common.Nondisclosure

			if(this.item_user.activity_info_release_fg == 'Y'){
				t = this.$language.common.disclosure
			}

			return t
		}
	}
	,methods: {
		getData: async function(){
			try {
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_my_info
					, data: {
						member_number: this.user.member_number
					}
					, type: true
				})
				if(result.success){
					this.item_user = result.data
				}else{
					throw 'not info'
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		,logout: async function(){
			this.$bus.$emit('logout')
		}

		,postLogout: async function(){
			console.log('postLogout')
			try {
				this.$bus.$emit('on', true)

				await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_logout
					, data: {
						member_number: this.user.member_number
					}
					, type: true
				})
			}catch(e){
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
				await this.logout()
			}
		}

		,postCartelActivity: async function(is_active){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_cartel_activity
					, data: {
						member_number: this.user.member_number
						, activity_info_release_fg: is_active ? 'Y' : 'N'
					}
					, type: true
				})
				if(result.success){
					this.$set(this.item_user, 'activity_info_release_fg', is_active ? 'Y' : 'N')
					this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success})
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
				console.log(e)
			}finally {
				this.is_confirm = false
				this.$bus.$emit('on', false)
			}
		}
		, onPinCallback: function(){
			this.$bus.$on('pinCallback', (pin_type) => {
				switch (pin_type){
					case 'check':
						this.onPin('update')
						break
					case 'update':
						this.offPinCallback()
						this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success})
						break
				}
			})
		}
		, offPinCallback: function(){
			this.$bus.$on('pinCallback', () => {
				console.log('pincallback off')
			})
		}
		, onPin: function(pin_type = 'check'){
			this.$bus.$emit('onPin', pin_type)
			this.onPinCallback(pin_type)
		}
		, onOtp: function(){
			this.$bus.$emit('onOtp', 'pin_confirm')
		}
		, postOtp: async function(otp){
			try {
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_google_change_otp
					, data: {
						member_number: this.user.member_number
						, gogle_otp_certfc_number: otp
					}
				})

				if(result.success){
					this.onPin('update')
				}else{
					throw result.message
				}
			}catch (e){
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}
		}
		, updatePin: function(){
			this.is_pin = false
			this.pin_option.pin_type = 'check'
			this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success})
		}
	}
	, async created() {
		await this.getData()
		await this.$emit('onLoad', this.program)
	}
	,watch: {
	}
}
</script>